import React from 'react';

import { Outlet } from 'react-router-dom';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import './AutomotorBuyerLayout.scss';

import AutomotorMenuNavbar from '../espace-automotor-layout/automotor-menu-navbar/AutomotorMenuNavbar';
import EspaceAutomotorBuyerNavbar from './navbar/EspaceAutomotorBuyerNavbar';
import ModalDlrnHernrCodfouByspecifyingReference from './modal/ModalDlrnHernrCodfouByspecifyingReference';
import EspaceReliableNavbarMobile from 'applications/automotor/components/espace/navbar/espace-reliable-navbar/Mobile/EspaceReliableNavbarMobile';


const AutomotorBuyerLayout: React.FC = () => {

  const { isMobile, isTablet } = useDeviceDetect();


  return (
    <div className="espace-automotor-buyer-container-wrapper">
      <div className="container">
        <div className="header">

          {isMobile || isTablet ? <EspaceReliableNavbarMobile /> : <EspaceAutomotorBuyerNavbar />}
          {isMobile || isTablet ? null : <AutomotorMenuNavbar />}
          <ModalDlrnHernrCodfouByspecifyingReference />
        </div>
        <Outlet />

      </div>
    </div>
  );
};

export default AutomotorBuyerLayout;
