import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { setupStore } from './store/store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import App from './App';
import './i18n';
import './index.scss';
import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY ?? '',
  plugins: [new BugsnagPluginReact()]
});

BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY ?? '' });

const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
const ErrorBoundary = plugin?.createErrorBoundary(React) || React.Fragment;

const ErrorView = () =>
  <div>
    <p>Inform users of an error in the component tree.</p>
  </div>;

// Bugsnag.notify(new Error('Test error22'));

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorView}>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
);

const toastRoot = document.getElementById('react_toast') as HTMLElement;

ReactDOM.createRoot(toastRoot).render(
  <React.StrictMode>
    <DefaultToastContainer />
  </React.StrictMode>,
);