import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Column } from 'react-table';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IData } from 'models/order';
import './OrderDetails.scss';
import { useTranslation } from 'react-i18next';
import DefaultTable from 'components/tables/DefaultTable/DefaultTable';
import useHttp from 'utils/hooks/useHttp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import CURRENCY from 'utils/constants/currency';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import { formatNumberWithSpaces } from 'utils/utils';

type Props = {

};

interface IOrderSummaryTable {
  name: string,
  value: string | number
}

interface IOrderItemTable {
  reference: string,
  quantity: number,
  price: number,
  supplier: string,
  status: string,
  total: number,
  country_restriction: boolean,
  description: string;
}

interface OrderItem {
  reference: string;
  price: number;
  supplier: string;
  quantity: number;
  status: string;
  total: number;
}

const OrderDetails: React.FC<Props> = () => {
  const navigate = useNavigate();
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { id } = useParams();
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingOrderItems, setLoadingOrderItems] = useState(false);
  const [orderSymmary, setOrderSymmary] = useState<Array<{ [key: string]: string | number }>>([]);
  const [orderItems, setOrderItems] = useState<Array<OrderItem>>([]);
  const [orderTotalPrice, setOrderTotalPrice] = useState<number>();
  const [data, setData] = useState<IData>();
  const [hasMore, setHasMore] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [page, setPage] = useState<number>(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const observer = useRef<IntersectionObserver | null>(null);
  const [loadingExport, setLoadingExport] = useState(false);

  function show(id: string, type) {
    document.getElementById(String(id))!.style.display = type;
  }

  const lastItemRef = useCallback(
    (node) => {
      if (loadingOrderItems) { return; }

      if (observer.current) { observer.current.disconnect(); }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          // Load more data when the last item is in view
          getItems(page + 1);
        }
      });

      if (node) { observer.current.observe(node); }
    },
    [loadingOrderItems, hasMore, page]
  );


  const columnsOrderSummary = useMemo(
    (): Column<IOrderSummaryTable>[] => [
      {
        Header: '1',
        columns: [
          {
            Header: '2',
            accessor: 'name',

          },
          {
            Header: '4',
            accessor: 'value',
          },
        ],
      },
    ],
    [
    ],
  );

  const columnsOrderItems = useMemo(
    (): Column<IOrderItemTable>[] => [


      {
        Header: `${t('_reference_sumitted')}`,
        accessor: 'reference',

        Cell: (cell) => {


          return <div className="country_restriction" >

            {cell.row.original.country_restriction ? (
              <React.Fragment key={cell.row.original.reference}>
                <FontAwesomeIcon
                  icon={faFlag}
                  className="font-icon"
                  onMouseEnter={() =>
                    show(String('country_restriction' + String(cell.row.original.reference)), 'block')
                  }
                  onMouseLeave={() =>
                    setTimeout(() => {
                      show(String('country_restriction' + String(cell.row.original.reference)), 'none');
                    }, 100)
                  }
                />
                <div
                  id={String('country_restriction' + String(cell.row.original.reference))}
                  className="restriction"
                >
                  {`${t('_country_restriction')}`}
                </div>
              </React.Fragment>

            ) : ''}

            {cell.row.original.reference}</div>;
        }
      },
      {
        Header: `${t('_description')}`,
        accessor: 'description',
      },
      {
        Header: `${t('_brand')}`,
        accessor: 'supplier',
      },
      {
        Header: `${t('_sell_price')}`,
        Cell: (row) => <div>{row.value ? CURRENCY[user!.currency] : ''}{row.value ? formatNumberWithSpaces(row.value) : ''}</div>,
        accessor: 'price',

      },
      {
        Header: `${t('_qte')}`,
        accessor: 'quantity',

      },
      {
        Header: `${t('_status')}`,
        accessor: 'status',

      },
      {
        Header: 'Total H.T',
        accessor: 'total',
        Cell: (row) => {
          return <div className="total">{CURRENCY[user!.currency]}{row.value ? formatNumberWithSpaces(row.value) : ''}</div>;
        }
      },
    ],
    [],
  );

  useEffect(() => {
    if (id) {
      setLoadingOrder(true);
      http.order.getOrderById(id).then(
        res => {


          setData(res.data);
          setOrderSymmary([
            { name: t('_order_submission'), value: res.data.time },
            { name: t('_confirmation_of_the_order'), value: 'YYYY/MM/DD' },
            // { name: t('_estimated_delivery'), value: res.data.delivery_date ?? 'YYYY/MM/DD' },
            { name: t('_request_of_delivery_date'), value: res.data.delivery_date ?? 'YYYY/MM/DD' },
            { name: t('_proforma_id'), value: res.data.m3_order_id ? String(res.data.m3_order_id) : res.data.proforma_id || '' },

          ]);
          setOrderTotalPrice(res.data.total_price);
        })
        .finally(
          () => { setLoadingOrder(false); }
        );


    }

    getItems(page);
  }, [i18n.language]);

  const getItems = (page) => {
    setLoadingOrderItems(true);
    http.order.getAutomotorOrderItems(id!, { page: page, per_page: 10 }).then((res) => {
      setPageCount(res.data.total_pages);

      const uniqueReferences = new Set(orderItems.map(item => item.reference));

      const uniqueNewItems = res.data.data.filter(item => !uniqueReferences.has(item.reference));

      setOrderItems(() => [
        ...orderItems,
        ...uniqueNewItems.map((item) => ({
          reference: item.reference,
          price: item.price,
          supplier: item.supplier,
          quantity: item.quantity,
          status: item.status,
          total: item.price * item.quantity,
          country_restriction: item.country_restriction
        })),
      ]);

      setHasMore(page < res.data.total_pages);
      setPage(res.data.page);
    }).finally(() => {
      setLoadingOrderItems(false);
    });
  };

  return (
    <div className="order-details-container">
      <div className="order-details-header">
        <div className="back-btn" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} /> {t('_return')}
        </div>
        <div className="export-btn">
          {
            data?.original_file ?
              loadingExport ? <Spinner class="extra-small" /> : <a className="btn-export" href={data.original_file} rel="noreferrer" > <FontAwesomeIcon icon={faDownload} className="icon" />    {`${t('_export_original_file')}`} </a>
              : null
          }
        </div>
      </div>

      <div className="title">
        {t('_summary_of_your_order')}
      </div>
      <div className="order-status">
        <div className="id">
          {t('_order')} <span>#{data?.id}</span>
        </div>
        <div className="status">
          {t('_status')}: <span>{data?.status && t(String(data?.status))}</span>
        </div>
      </div>
      <div className="order-tables">
        <div className="order-summary">
          <DefaultTable columns={columnsOrderSummary} data={orderSymmary} total={orderTotalPrice} loading={loadingOrder} header={false} />
        </div>
        <div className="automotor-order-items">
          <DefaultTable lastItemRef={lastItemRef} columns={columnsOrderItems} data={orderItems} loading={loadingOrderItems} />
        </div>
        {/* {pageCount > 1 && <Pagination pageCount={pageCount} forcePage={page} handlePageClick={handlePageClick} />} */}
      </div>

    </div >

  );
};

OrderDetails.defaultProps = {
  // currentPage: 1
};


OrderDetails.displayName = 'SellersTable';

export default OrderDetails;
