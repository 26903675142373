import React, { ChangeEventHandler, PropsWithChildren, KeyboardEventHandler } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';

import './TextInput.scss';
import { ICustomValidation } from '../../../utils/hooks/http/search-by-reference.http';
import CustomValidationMessages from './CustomValidationMessages/CustomValidationMessages';

type Props = {
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  name?: string;
  value?: string;
  withIcon?: boolean;
  onClick?: (event) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  clearable?: boolean;
  onClickReset?: () => void;
  register?
  type?: string;
  defaultValue?: string | number;
  readOnly?: boolean;
  validationError?: boolean
  whiteColor?: boolean
  disabled?: boolean
  customValidations?: ICustomValidation[]
  customValidationMessages: string[]
  autoComplete?: string
  component?: React.ReactNode;
};

const TextInputView = React.forwardRef<HTMLInputElement, Props>(({ customValidationMessages, withIcon, clearable, onClickReset, ...props }: PropsWithChildren<Props>, ref) => {

  const newProps = { ...props };

  delete newProps.whiteColor;
  delete newProps.validationError;
  delete newProps.customValidations;
  delete newProps.onChange;
  delete newProps.onClick;

  return (
    <div className={`text-input-container ${props.disabled ? 'disabled' : ''}`}>
      <input className={`text-input ${props.validationError || customValidationMessages.length ? 'input-error' : ''} ${props.whiteColor ? 'white' : ''}`} type={props.type ? props.type : 'text'} onChange={props.onChange} {...newProps} ref={ref} readOnly={props.readOnly} />
      {clearable && <button className="close-icon" aria-label="Reset" type="reset" onClick={onClickReset} > <FontAwesomeIcon className="input-close-icon" icon={faClose} /> </button>}
      {withIcon && !clearable && <div onClick={props.onClick}><FontAwesomeIcon className="input-search-icon-fa" icon={faSearch} /></div>}
      {props.component && props.component}
      <CustomValidationMessages customValidationMessages={customValidationMessages}></CustomValidationMessages>
    </div>
  );
});

TextInputView.displayName = 'TextInputView';

export default TextInputView;
