import React from 'react';
import { useTranslation } from 'react-i18next';
import VinSearch from '../vin-search/VinSearch';
import PlateSearch from '../plate-search/PlateSearch';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Tabs from '../vehicle-card/Tabs/Tabs';
import Tab from '../vehicle-card/Tabs/Tab';
import { ReactComponent as VinIcon } from 'assets/Icons/vin-icon.svg';
import { ReactComponent as PlateNumberIcon } from 'assets/Icons/plate-number-icon.svg';
import './VinPlateCard.scss';


type Props = {
  setOpenModal?: (e: boolean) => void;
  link?: string;
};

const VinPlateCardView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);

  return (
    <div className="search-card">
      {
        user?.numcli !== Number(process.env.REACT_APP_MECADEPOT_NUMCLI) ?
          <div className="card-title">{t('_you_know_vehicle_in_front')}</div>
          : null
      }
      <div className="vin-plate-container">
        <Tabs>
          <Tab title={<div className="title"><VinIcon /></div>}>
            <VinSearch setOpenModal={props.setOpenModal} link={props.link} />
          </Tab>
          {user?.client_settings.plate_number_search ? (
            <Tab
              title={
                <div className="title">
                  <PlateNumberIcon />
                  <span>{t('_plate')}</span>
                </div>
              }
            >
              <PlateSearch setOpenModal={props.setOpenModal} link={props.link} />
            </Tab>
          ) : null}
        </Tabs>
      </div>
    </div>
  );
};

VinPlateCardView.displayName = 'VinPlateCardView';

export default VinPlateCardView;
