
import { IHttpResponse, serverAxios } from '../../http';

class BuyerHttp {
  private static list = '/buyer/check-equivalences';
  public postList(dataFormPost: FormData): Promise<{ data }> {
    return serverAxios.post(BuyerHttp.list, dataFormPost, { responseType: 'blob' });
  }

}

export default BuyerHttp;
