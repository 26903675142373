import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/modal/Modal';
import { setDlrnHernrCodfouByspecifyingReferenceActive } from 'store/slices/dlrnHernrCodfouByspecifyingReference.slice';
import { RootState } from 'store/store';
import ModalDlrnHernrCodfouByspecifyingReferenceContent from './UploadProformaAutomotorContent/ModalDlrnHernrCodfouByspecifyingReferenceContent';


type Props = {};

const ModalDlrnHernrCodfouByspecifyingReference: React.FC<Props> = () => {
  const { dlrnHernrCodfouByspecifyingReferenceActive } = useSelector((state: RootState) => state.dlrnHernrCodfouByspecifyingReference);
  const [openModal, setOpenModal] = useState<boolean>(dlrnHernrCodfouByspecifyingReferenceActive);
  const dispatch = useDispatch();

  useEffect(() => {


    setOpenModal(dlrnHernrCodfouByspecifyingReferenceActive);
  }, [dlrnHernrCodfouByspecifyingReferenceActive]);

  useEffect(() => {
    if (!openModal) {
      dispatch(setDlrnHernrCodfouByspecifyingReferenceActive(false));
    }
  }, [openModal]);

  return (
    <div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={<ModalDlrnHernrCodfouByspecifyingReferenceContent setOpenModal={setOpenModal} />}
      />

    </div>
  );
};

ModalDlrnHernrCodfouByspecifyingReference.displayName = 'ModalDlrnHernrCodfouByspecifyingReference';

export default ModalDlrnHernrCodfouByspecifyingReference;