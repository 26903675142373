import React, { useMemo, useState } from 'react';
import { Column, useExpanded, useTable } from 'react-table';

import { useSearchParams } from 'react-router-dom';
import { IData } from 'models/order';
import Spinner from 'components/spinner/Spinner';
import Head from 'components/tables/Cells/Head/Head';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import i18n from 'i18n';
import Pagination from 'components/pagination/Pagination';

import useHttp from 'utils/hooks/useHttp';
import Modal from 'components/modal/Modal';
import OrderConfirmationModal from '../OfferConfirmationModal/OfferConfirmationModal';
import { notify } from 'utils/marketplace';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileImport } from '@fortawesome/free-solid-svg-icons';
import './ClientOfferTable.scss';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';


type Props = {
  data: IData[]
  loading: boolean
  handlePageClick?: (data) => void
  totalPage?: number
  currentPage?: number
  setOpenDetails?
  hiddenColumns?
  lastItemRef?
  getClientOfferList?
  orderAction: string;
  setOrderAction?
};


const ClientOfferTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [orderId, setOrderId] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();

  function onConfirmOrder() {
    setLoading(true);
    http.order.orderAction(String(orderId), { action: props.orderAction })
      .then(() => {
        notify(t('_success'), 'success');
        props.getClientOfferList();
      })
      .catch(() => notify(t('_error')))
      .finally(() => {
        setLoading(false);
        setOpenModal(false);
      });
  }

  function onOpenModal(e) {
    e.stopPropagation();
    setOpenModal(true);
  }

  const columns = useMemo(
    (): Column<IData>[] => [
      {
        id: 'expander',
        Header: '',
        width: 15,
        Cell: (row) => {
          return <ExpandButton row={row.row} />;
        },
      },
      {
        Header: <Head text={t('_id').toLocaleUpperCase()}></Head>,
        accessor: 'id',
      },
      {
        Header: <Head text={t('_price').toLocaleUpperCase()}></Head>,
        accessor: 'total_price',
        Cell: (props) => { return (props.row.values.total_price ? props.row.values.total_price.toFixed(2) : 0); },
      },
      {
        Header: <Head text={t('_client').toLocaleUpperCase()}></Head>,
        accessor: 'client_full_name',
      },
      {
        Header: <Head text={t('_name').toLocaleUpperCase()}></Head>,
        accessor: 'user_full_name',
      },
      {
        Header: <Head text={t('_email').toLocaleUpperCase()}></Head>,
        accessor: 'user_email',
      },
      {
        Header: <Head text={t('_order_id').toLocaleUpperCase()}></Head>,
        accessor: 'proforma_id',
        Cell: (props) => <span>{props.row.original.m3_order_id ? props.row.original.m3_order_id : props.row.original.proforma_id}</span>
      },
      {
        Header: <Head text={t('_date').toLocaleUpperCase()}></Head>,
        accessor: 'time',
      },
      {
        Header: <Head text={t('_status').toLocaleUpperCase()}></Head>,
        accessor: 'status',
        Cell: (props) => <span>{t(props.row.original?.status)}</span>
      },
      {
        Header: <Head text={t('_actions').toLocaleUpperCase()}></Head>,
        accessor: 'actions',
        Cell: (prop) => (
          <span>
            <DefaultButton text={isMobile ? <FontAwesomeIcon icon={faFileImport} /> : t('_export')} className="export-order-btn" onClick={(e) => exportFile(e, prop.row.original.id)} isloading={exportLoading} />
            {isMobile && <DefaultButton text={<FontAwesomeIcon icon={faEye} />} className="export-order-btn" onClick={() => handleRowClick(prop.row.original.id)} />}
          </span>
        )
      },
    ],
    [i18n.language, isMobile, isTablet, isDesktop],
  );

  if (!isMobile) {
    columns.shift();
  }

  const hiddenColumns = isMobile ? ['total_price', 'user_email', 'time', 'proforma_id', 'user_full_name'] : [];

  const initialState = hiddenColumns ? { hiddenColumns } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.data,
    initialState: initialState
  },
    useExpanded
  );

  function handleRowClick(id) {
    props.setOpenDetails(true);

    setSearchParams({ ...searchParams, id });
  }

  function exportFile(e, id) {
    e.stopPropagation();
    setExportLoading(true);
    http.order.orderExport(id).then(res => {
      const element = document.createElement('a');

      const file = new Blob(
        [res.data],
        { type: res.type },
      );

      element.href = URL.createObjectURL(file);
      element.download = `order-${id}.xlsx`;
      element.click();

    }).finally(() => {
      setExportLoading(false);
    });
  }

  return (
    <div className="client-offer-table">
      <div className="client-offer-table-container">
        {props.loading ? <Spinner class="sellers-table-spinner" /> :
          props.data.length ? (
            <table {...getTableProps()}>

              <thead className="headings">
                {headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={headerGroup.headers[index].id} className="row">
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps();

                        return (
                          <th key={key} {...restColumn} className="heading">
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row, index) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  if (index === rows.length - 1) {
                    return (
                      <tr ref={props.lastItemRef} key={key} {...restRowProps} onClick={() => !isMobile && handleRowClick(row.original.id)} className="row">
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }

                  return (
                    <React.Fragment key={key}>
                      <tr {...restRowProps} onClick={() => !isMobile && handleRowClick(row.original.id)} className="row">
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: hiddenColumns, extraFieldsNumber: 2 }) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          ) :
            <div className="offer-no-data">{t('_no_data')}</div>
        }
        {/* {props.loading && <Spinner class={`extra-small order-table-small-spinner ${props.data.length ? 'has-data' : ''} `} />} */}
      </div>

      <div>
        {props.totalPage! > 1 && (
          <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage!} />
        )}
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<OrderConfirmationModal setOpenModal={setOpenModal} loading={loading} onConfirmOrder={onConfirmOrder} orderAction={props.orderAction} />}
      />
    </div>
  );
};

ClientOfferTable.defaultProps = {
  currentPage: 1
};

export default ClientOfferTable;
