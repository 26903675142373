import React from 'react';
import { useTranslation } from 'react-i18next';

import { setDlrnHernrCodfouByspecifyingReferenceActive } from 'store/slices/dlrnHernrCodfouByspecifyingReference.slice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';


const c = 'automotor-menu-navbar';

const DlrnHernrCodfouByspecifyingReference: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDlrnHernrCodfouByspecifyingReference = () => {


    dispatch(setDlrnHernrCodfouByspecifyingReferenceActive(true));
  };

  return (
    <div className={`${c}__item--parent__children__item--title`} onClick={handleDlrnHernrCodfouByspecifyingReference}>
      <FontAwesomeIcon icon={faListAlt} />
      <span>{t('_rading_DLNR_HERNR_CODFOU_by_specifying_reference')}</span>
    </div>
  );
};

export default DlrnHernrCodfouByspecifyingReference;
