import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column, useExpanded, useTable } from 'react-table';
import { faAdd, faEdit, faFilter, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IOption } from 'interfaces/select';
import Modal from 'components/modal/Modal';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';
import Head from 'components/tables/Cells/Head/Head';
import Pagination from 'components/pagination/Pagination';
import PartsReplacementFilter from './Filter/PartsReplacementFilter';
import CreatePartsReplacement from './ModalCreate/CreatePartsReplacement';
import { IPartsReplacementDetails } from 'utils/hooks/http/parts-replacement.http';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import UploadPartReplacementModal from './UploadPartReplacementModal/UploadPartReplacementModal';
import './PartsReplacement.scss';

type Props = {};

const PartsReplacement: React.FC<Props> = () => {
  const http = useHttp();
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = React.useState<IPartsReplacementDetails[]>([]);
  const [params, setParams] = React.useState({ page: Number(searchParams.get('page') || 1) });
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [pageCount, setPageCount] = useState(0);
  const [forcePage, setForcePage] = useState<number>(Number(searchParams.get('page') || 1));
  const [submit, setSubmit] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(false);
  const [providers, setProviders] = useState<IOption[]>([]);
  const [editData, setEditData] = useState<IPartsReplacementDetails>();
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);

  const request = (params) => {
    http.partsReplacement
      .getPartsReplacement(params)
      .then((res) => {
        setData(res.data.data);
        setPageCount(res.data.total_pages);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    http.allowedList.getProviders()
      .then((res) => {
        setProviders(res.data.map((item) => ({ value: item.code, label: `${item.company} (${item.code})` })));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    request(params);
    setSubmit(false);
    setSearchParams({ page: String(params.page) });
  }, [params]);

  useEffect(() => {
    if (submit) {
      setLoading(true);
      request(params);
      setSubmit(false);
    }
  }, [submit]);

  const columns = useMemo(
    (): Column<IPartsReplacementDetails>[] => [

      {
        id: 'expander',
        Header: '',
        width: 10,
        maxWidth: 10,
        Cell: (props) => {
          return (
            <ExpandButton row={props.row} />
          );
        }
      },
      {
        Header: <Head text={t('_codfou_old')}></Head>,
        accessor: 'codfou_old'
      },
      {
        Header: <Head text={t('_ref_old')}></Head>,
        accessor: 'ref_old',
      },
      {
        Header: <Head text={t('_ref_old_raw')}></Head>,
        accessor: 'ref_old_raw',
      },
      {
        Header: <Head text={t('_codfou_new')}></Head>,
        accessor: 'codfou_new'
      },
      {
        Header: <Head text={t('_ref_new')}></Head>,
        accessor: 'ref_new',
      },
      {
        Header: <Head text={t('_ref_new_raw')}></Head>,
        accessor: 'ref_new_raw',
      },
      {
        Header: <Head text={t('_actions')}></Head>,
        accessor: 'id',
        width: isDesktop ? 30 : 60,
        maxWidth: isDesktop ? 30 : 60,
        Cell: (cell) => (
          <div className="row-align-right">
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => {
                setOpenModalEdit(true);
                setEditData(cell.row.original);
              }}
            />
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => {
                setDeleteId(Number(cell.row.original.id));
                setOpenModalDelete(true);
              }}
            />
          </div>
        ),
      },
    ],
    [i18n.language, data, isMobile, isTablet, isDesktop],
  );

  let hiddenColumns: string[] = [ 'codfou_new', 'ref_new_raw', 'ref_old_raw'];

  if (isMobile) {
    hiddenColumns = [...hiddenColumns, 'codfou_old'];
  }


  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  if (!isMobile && !isTablet) {
    columns.shift();
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: data,
      initialState
    }, useExpanded);

  const handlePageClick = (page: number) => {
    setForcePage(page);
    setSearchParams({ page: String(page) });
    setParams({ ...params, ...{ page } });
  };

  return (
    <div className="parts_replacement_header">
      <div className="parts_replacement_title">{t('_parts_replacement')}</div>
      <div className="action-btns-container">
        <div className="parts_replacement_upload">
          <DefaultButton text={t('_import')} onClick={() => setOpenUpload(true)} />
        </div>
        {!isMobile ?
          <div className="filter-link-btn">
            <DefaultButton
              text={
                <React.Fragment>
                  <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_part_replacement')}
                </React.Fragment>
              }
              className="create-part-replacement"
              onClick={() => setOpenModal(true)}
            />
          </div> : null}
      </div>

      {isMobile ? (
        <div className="filter-btns">
          <button className="button-filter-parts_replacement" onClick={() => setIsActive(true)}>
            <FontAwesomeIcon icon={faFilter} /> {t('_filter')}
          </button>
          <div className="filter-link-btn">
            <button className="add-btn" onClick={() => setOpenModal(true)}>
              <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_part_replacement')}
            </button>
          </div>
        </div>
      ) : null}

      <div className={`parts_replacement-filter ${isMobile && !isActive ? 'hidden-filter-parts_replacement' : 'show-filter-parts_replacement'}`}>
        <PartsReplacementFilter
          setOpenModal={setOpenModal}
          setParams={setParams}
          setForcePage={setForcePage}
          setIsActive={setIsActive}
          providers={providers}
        />
      </div>

      <div className="table-wrapper">
        {loading ? (
          <Spinner />
        ) : (
          data.length ? (
            <table {...getTableProps()}>
              <thead className="thead">
                {headerGroups.map((headerGroup, key) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps({
                          style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                        })}
                        key={index}
                        className="heading"
                      >
                        <div className="table-title">{column.render('Header')}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {loading && data.length ? (
                <tbody>
                  <tr>
                    <td colSpan={Object.keys(rows[0].original).length}>
                      <Spinner class="extra-small" />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody {...getTableBodyProps} className="body">
                  {!loading && data.length ? (
                    rows.map((row) => {
                      prepareRow(row);
                      const { key, ...restRowProps } = row.getRowProps();

                      return (
                        <React.Fragment key={key}>
                          <tr key={key} {...restRowProps} className="cell-row">
                            {row.cells.map((cell) => {
                              const { key, ...restCellProps } = cell.getCellProps();

                              return (
                                <td key={key} {...restCellProps} className="cell">
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                          {row.isExpanded ? TableMoreInformation({ row, hiddenColumns, extraFieldsNumber: 2 }) : null}
                        </React.Fragment>
                      );
                    })
                  ) : null}
                </tbody>
              )}
            </table>
          ) : <div className="order-no-data">{t('_no_data')}</div>
        )}
        {pageCount > 1 && !loading ? (
          <Pagination pageCount={pageCount} forcePage={forcePage} handlePageClick={handlePageClick} hiddenParamPage />
        ) : null}
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={
          <CreatePartsReplacement
            setOpenModal={setOpenModal}
            setSubmit={setSubmit}
            loading={loading}
            providers={providers}
          />
        }
      />
      <Modal
        shouldCloseOnOverlayClick={false}
        openModal={openModalEdit}
        setOpenModal={setOpenModalEdit}
        childrenComponent={
          <CreatePartsReplacement
            setOpenModal={setOpenModalEdit}
            setSubmit={setSubmit}
            loading={loading}
            providers={providers}
            editData={editData}
          />
        }
      />
      <Modal
        shouldCloseOnOverlayClick={false}
        openModal={openUpload}
        setOpenModal={setOpenUpload}
        childrenComponent={
          <UploadPartReplacementModal
            setOpenModal={setOpenUpload}
          />
        }
      />
      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={
          <ModalDelete
            setOpenModalDelete={setOpenModalDelete}
            setSubmit={setSubmit}
            id={deleteId}
            link={http.partsReplacement.deletePartsReplacement}
          />
        }
      />
    </div>
  );
};

export default PartsReplacement;
