import React, { ReactElement, SVGProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './AutomotorMenuNavbar.scss';

import { getAutomotorMenuList, IMenuItem } from './menu-automotor-data';

type Props = {};

const c = 'automotor-menu-navbar';

const AutomotorMenuNavbar: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const [menuList, setMenuList] = useState<IMenuItem[]>([]);


  function renderIcon(icon: React.ComponentType<SVGProps<SVGSVGElement>> | ReactElement) {
    if (React.isValidElement(icon)) {
      return icon;
    }

    const Icon = icon as React.ComponentType<SVGProps<SVGSVGElement>>;

    return <Icon className="automotor-nav-icon" width={20} height={20} />;
  }

  useEffect(() => {
    if (user?.full_name) {
      setMenuList(getAutomotorMenuList(user));
    }

  }, [user?.full_name]);

  return (
    <div className={c}>
      <div className={`${c}__list`}>
        {menuList.map((item, index) => {
          // Type guard to check if item has a 'role' property
          const hasRoles = (item: IMenuItem | { separator: boolean }): item is IMenuItem =>
            'role' in item;

          if (hasRoles(item) && item.role.length && !item.role.some((role) => user?.roles.includes(role))) {
            return null;
          }

          return (
            <div key={index} className={`${c}__item`}>
              {item.children && item.children.length ? (
                <div className={`${c}__item--parent`}>
                  <div className={`${c}__item--parent__title`}>
                    {renderIcon(item.image)}
                    <span>
                      {t(item.name)} <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                  </div>
                  <div className={`${c}__item--parent__children`}>
                    {item.children.map((child, childIndex) => {
                      if (hasRoles(child) && child.role.length && !child.role.some((role) => user?.roles.includes(role))) {


                        return null;
                      }

                      if ('separator' in child && child.separator === true) {
                        return <div key={`separator-${item.name}-${childIndex}`} className={`${c}__item--parent__children__separator`} />;
                      }

                      if ('custom' in child && child.custom) {
                        return (
                          <div key={childIndex} className={`${c}__item--parent__children__item`}>
                            {child.custom}
                          </div>
                        );
                      }

                      if ('callback' in child && child.callback) {
                        return (
                          <div key={childIndex} className={`${c}__item--parent__children__item`}>
                            <div className={`${c}__item--parent__children__item--title`} onClick={child.callback}>
                              {renderIcon(child.image)}
                              <span>{t(child.name)}</span>
                            </div>
                          </div>
                        );
                      }

                      if ('name' in child) {
                        return (
                          <div key={childIndex} className={`${c}__item--parent__children__item ${child?.disabled ? 'disabled' : ''}`}>
                            <NavLink className={`${c}__item--parent__children__item--title`} to={child.url || ''}>
                              {renderIcon(child.image)}
                              <span>{t(child.name)}</span>
                            </NavLink>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              ) : (
                <div className={`${c}__item--child`}>
                  {renderIcon(item.image)}
                  <span>{t(item.name)}</span>
                </div>
              )}
            </div>
          );
        })}

      </div>

    </div>
  );
};

export default AutomotorMenuNavbar;
