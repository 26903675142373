import { IPromise } from 'interfaces/selectClient';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';
import { IFilterValue } from 'interfaces/tyre';

class AllowedListHttp {
  private static listRoute = 'allowed-lists';
  private static saveRoute = 'allowed-lists/save';
  private static deleteRoute = 'allowed-lists/:id';
  private static itemById = 'allowed-lists/:id';
  private static updateRoute = 'allowed-lists/:id/update';
  private static clients = 'seller/clients-simple';
  private static providers = 'automotor/providers';
  private static references = 'allowed-lists/:id/references';

  public getAllowedReferences(
    params: { page?: number; file_id: string; reference?: string },
    id: string,
  ): Promise<IHttpResponse<IReferencesResponse>> {
    return serverAxios.get(addRouteParam(AllowedListHttp.references, { id }), { params });
  }

  public getAllowedList(urlParams: { page: number }): Promise<IHttpResponse<IResponse>> {
    return serverAxios.get(AllowedListHttp.listRoute, { params: urlParams });
  }

  public getAllowedListById(id: number): Promise<IHttpResponse<IAllowedListDetails>> {
    return serverAxios.get(addRouteParam(AllowedListHttp.itemById, { id }));
  }

  public getClients(): Promise<IPromise> {
    return serverAxios.get(AllowedListHttp.clients);
  }

  public getProviders(): Promise<IHttpResponse<{ code: string; company: string, source: string }[]>> {
    return serverAxios.get(AllowedListHttp.providers);
  }

  public editAllowedPart(data: FormData, id: number): Promise<IHttpResponse<{}>> {
    return serverAxios.post(addRouteParam(AllowedListHttp.updateRoute, { id }), data);
  }

  public saveAllowedPart(data: FormData): Promise<IHttpResponse<{}>> {
    return serverAxios.post(AllowedListHttp.saveRoute, data);
  }

  public deleteAllowedPart(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(addRouteParam(AllowedListHttp.deleteRoute, { id }));
  }
}

export interface IResponse {
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
  data: Array<IAllowedListDetails>;
}

export interface IReferencesResponse {
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
  data: Array<IReference>;
}

export interface IReference {
  id: number;
  id_list: string;
  reference: string;
  reference_raw: string;
  provider: { codfou: string; company: string };
}

export interface IAllowedListDetails {
  id: number;
  title: string;
  comments: string;
  provider: { codfou: string; company: string };
  file: string;
  numcli: IFilterValue[];
  created_at: string;
  created_by: string;
}

export default AllowedListHttp;
