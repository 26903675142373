

import { RefObject } from 'react';
import { getCurrentMarketplace } from './marketplace';
import { Marketplaces } from './constants/applications';

export function getRefValue<C>(ref: RefObject<C>): C {
  return ref.current as C;
}

export const NUMBER_REGEX = /\d+/;

export const CATEGORY_FROM_POLK = 'P';

export const CATEGORY_FROM_POLK_2 = 'PT';

export const REDIRECTED_HREF_KEY = 'redirectedHref';


export function getRedirectedHref(): string {
  const { href } = window.location;

  return href.split(REDIRECTED_HREF_KEY + '=')[1];

}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}


export function formatNumber(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function formatNumberWithSpaces(value: number | string | null | undefined): number | string {
  if (value === undefined || value === null || value === '') {
    return '-';
  }

  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return '-';
  }

  return numberValue
    .toFixed(2)  // Setează două zecimale
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');  // Adaugă spațiu la miimi
}


//function that receives an object and filter values to not be null / undefined. This function also check if value is an array and filter it too
export function filterObject(obj: object): object {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null && obj[key] !== undefined) {
      if (Array.isArray(obj[key])) {
        const filterArray = obj[key].filter((el: number | string) => {
          return el !== null && el !== undefined && el !== 'undefined';
        });

        if (filterArray.length > 0) {
          acc[key] = filterArray;
        }

      } else if (typeof obj[key] === 'object') {
        acc[key] = filterObject(obj[key]);
      } else {
        acc[key] = obj[key];
      }
    }

    return acc;
  }, {});
}


export function getGenerat(el: number): string {
  let a: string;

  switch (String(el).length) {
    case 1:
      a = `0000${String(el)}`;

      break;
    case 2:
      a = `000${String(el)}`;

      break;
    case 3:
      a = `00${String(el)}`;

      break;
    case 4:
      a = `0${String(el)}`;

      break;
    default:
      a = String(el);

      break;
  }

  return String(a);
}

export function isPublic(): boolean {
  if (
    getCurrentMarketplace() === Marketplaces.PUBLIC
    || getCurrentMarketplace() === Marketplaces.PUBLIC_DZ
    || getCurrentMarketplace() === Marketplaces.PUBLIC_MA
    || getCurrentMarketplace() === Marketplaces.PUBLIC_TN
    || getCurrentMarketplace() === Marketplaces.PUBLIC_TR) {
    return true;
  }

  return false;
}

export function checkIfImageExists(url: string, callback: (boolean) => void): void {
  const img = new Image();

  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}