import React, { useState } from 'react';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faRepeat, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CURRENCY from 'utils/constants/currency';
import './CartTableEspace.scss';
import { formatNumberWithSpaces } from 'utils/utils';

type Props = {
  data;
  loading?: boolean;
  handlePageClick?: (data) => Promise<void>;
  totalPage?: number;
  currentPage?: number;
  total?: number;
  tableRef?: React.RefObject<HTMLTableElement>;
  loadingContent?: boolean;
  header?: boolean;
  onRowClick?: (row) => void;
  setValueItems?;
  removeRow?;
  valueChangeRepaced?: (number) => void;
  changePartInd?: (boolean, number) => void;
  noFooter?: boolean
};

const CartTableEspace: React.FC<Props> = (props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  function show(id: string, type) {
    document.getElementById(String(id))!.style.display = type;
  }


  return (
    <div className="table-wrapper-parts-espace">
      {props.loading ? <Spinner /> : null}

      < table >
        <thead className="headings">
          <tr className="row">
            <th className="heading" colSpan={3} >
              {/* <div className="table-title">{t('_submitted_info')}</div> */}
              <div className="table-title">{' '}</div>
            </th>
            <th className="heading" colSpan={2}>
              {/* <div className="table-title">{t('_information')}</div> */}
              <div className="table-title">{' '}</div>
            </th>
            <th className="heading" rowSpan={2}>
              <div className="table-title">{t('_total')}</div>
            </th>
          </tr>
          <tr className="row">
            <th className="heading" colSpan={1} >
              {t('_num')}
            </th>
            <th className="heading" colSpan={1}>
              {t('_reference_sumitted')}
            </th>

            <th className="heading" colSpan={1}>
              {t('_brand')}
            </th>

            <th className="heading" colSpan={1}>
              {t('_sell_price')}
            </th>


            <th className="heading" colSpan={1}>
              {t('_qte')}
            </th>

          </tr>
        </thead>


        <tbody className="body">
          {props.data.map((item, key) => {
            let validate_franco = false;
            let total_provider = 0;

            item.parts.map((i) => {


              total_provider += i.quantity * i.price;
              validate_franco = total_provider < item.franco!;
            });

            return (
              <React.Fragment key={item.code_fou + key}>
                <tr key={item.code_fou + key} className="row" onClick={() => null}>
                  <td colSpan={7} className="cell-title">
                    <div className="cell-title-franco">

                      <span className={`franco ${validate_franco ? 'red' : 'not-red'}`} >
                        {
                          validate_franco
                            ?
                            <React.Fragment key={item.code_fou + item.parts[0].reference_full + key}>
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                onMouseEnter={() =>
                                  show(String('franco' + String(item.code_fou + item.parts[0].reference_full + key)), 'block')
                                }
                                onMouseLeave={() =>
                                  setTimeout(() => {
                                    show(String('franco' + String(item.code_fou + item.parts[0].reference_full + key)), 'none');
                                  }, 100)
                                }
                              />

                              <div
                                id={String('franco' + String(item.code_fou + item.parts[0].reference_full + key))}
                                className="min_franco"
                              >
                                {t('_min_franco')} {CURRENCY[user!.currency]}
                                {item.franco ? item.franco : '-'}{' '}
                              </div>
                            </React.Fragment>
                            : ''
                        }
                        {t('_franco')}: {CURRENCY[user!.currency]}
                        {item.franco ? item.franco : '-'}
                      </span>


                      <span> {item.name == 'NOT_SUPPLIED' ? <span className="red">{t('_not_supplied')} </span> : item.name}</span>

                      <span>
                        {t('_total')}: {CURRENCY[user!.currency]}
                        {formatNumberWithSpaces(total_provider)}
                      </span>
                    </div>

                  </td>
                </tr>

                {
                  item.parts.map((i, n) => {
                    const validate_quantity = i.quantity < i.min_quantity;

                    const validate_uv = i.quantity % i.uv != 0;

                    return (
                      <tr key={i.reference + item.name + n} className="row">

                        <td colSpan={1} className="cell" style={{ width: '30' }}>{n + 1}</td>

                        <td colSpan={1} className="cell" style={{ width: '200' }}>
                          <div className="reference-head">
                            <div className="reference-name">
                              {validate_quantity && (
                                <React.Fragment key={i.reference + item.name + n}>
                                  <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    className="font-icon"
                                    onMouseEnter={() =>
                                      show(String('ref_validate_quantity' + String(i.reference + item.name + n)), 'block')
                                    }
                                    onMouseLeave={() =>
                                      setTimeout(() => {
                                        show(String('ref_validate_quantity' + String(i.reference + item.name + n)), 'none');
                                      }, 100)
                                    }
                                  />
                                  <div id={String('ref_validate_quantity' + String(i.reference + item.name + n))} className="min_quantity" >
                                    {' '}
                                    {t('_min_quantity')} {i.min_quantity ? i.min_quantity : '-'}{' '}
                                  </div>
                                </React.Fragment>
                              )}

                              <span className="reference"> {(i.code_fou ? i.code_fou : '') + i.reference}</span>

                            </div>


                          </div>
                        </td>

                        <td colSpan={1} className="cell">
                          <span >{i.name} </span>
                        </td>

                        <td colSpan={1} className="cell">
                          <div className="price_head">
                            <div className="price">
                              {i.price ? (<span >{CURRENCY[user!.currency]} {formatNumberWithSpaces(i.price)}</span>) : (<span>-</span>)}
                            </div>
                          </div>
                        </td>


                        <td colSpan={1} className="cell">

                          <QuantityInput
                            value={i.quantity}
                            index={i.index}
                            id={i.id}
                            setValueItems={props.setValueItems}
                            uv={i.uv}
                            red={(validate_uv || validate_quantity) && item.name != 'NOT_SUPPLIED'}
                          />
                          {i.uv && i.uv > 1 && (
                            <React.Fragment>
                              <span className="color-yellow">UV * {i.uv}</span>
                              <br></br>
                            </React.Fragment>
                          )}

                          {validate_uv && !validate_quantity && item.name != 'NOT_SUPPLIED' && (
                            <span className="color-red">
                              {t('_validate_uv')} : {i.uv}
                            </span>
                          )}
                          {!validate_uv && validate_quantity && item.name != 'NOT_SUPPLIED' && (
                            <span className="color-red">
                              {t('_min_quantity')} {i.min_quantity ? i.min_quantity : '-'}
                            </span>
                          )}
                          {validate_uv && validate_quantity && item.name != 'NOT_SUPPLIED' && (
                            <div>
                              <span className="color-red">
                                {t('_validate_uv')} : {i.uv}
                              </span>
                              <br></br>
                              <span className="color-red">
                                {t('_min_quantity')} {i.min_quantity ? i.min_quantity : '-'}
                              </span>
                            </div>
                          )}
                        </td>

                        <td colSpan={1} className="cell">
                          <div className="action">
                            {Number(i.total_price) ? CURRENCY[user!.currency] : ''}{' '}
                            {Number(i.total_price) ? formatNumberWithSpaces(i.total_price) : '-'}

                            <div className="action_btn" key={i.id}>
                              <button className="action_btn_remove" onClick={() => props.removeRow(i.id)}>
                                <FontAwesomeIcon icon={faTrashCan} className="action_btn_remove_icon" />
                              </button>
                            </div>

                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </React.Fragment>
            );
          })}
        </tbody>


      </table>
      {
        !props.noFooter && (
          <div className="tfoot">

            <div className="tfoot-td-1">{t('_total_h_t')}</div>
            <div className="tfoot-td-2">
              {CURRENCY[user!.currency]}
              {Number(props.total) ? formatNumberWithSpaces(props.total) : 0}


            </div>

          </div>
        )
      }


    </div >
  );
};

CartTableEspace.displayName = 'CartTableEspace';

export default CartTableEspace;
