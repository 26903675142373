import { createSlice } from '@reduxjs/toolkit';

type dlrnHernrCodfouByspecifyingReferenceSlice = {
  dlrnHernrCodfouByspecifyingReferenceActive: boolean;
  isLoading: boolean;
};

const initialState: dlrnHernrCodfouByspecifyingReferenceSlice = {
  dlrnHernrCodfouByspecifyingReferenceActive: false,
  isLoading: false,
};

const dlrnHernrCodfouByspecifyingReferenceSlice = createSlice({
  name: 'dlrnHernrCodfouByspecifyingReferenceSlice',
  initialState,
  reducers: {
    setDlrnHernrCodfouByspecifyingReferenceActive(state, { payload }) {
      state.dlrnHernrCodfouByspecifyingReferenceActive = payload;
    },
    setIsdlrnHernrCodfouByspecifyingReferenceLoading(state, { payload }) {
      state.isLoading = payload;
    },

  },

});

export const { setDlrnHernrCodfouByspecifyingReferenceActive, setIsdlrnHernrCodfouByspecifyingReferenceLoading } = dlrnHernrCodfouByspecifyingReferenceSlice.actions;

export default dlrnHernrCodfouByspecifyingReferenceSlice.reducer;
