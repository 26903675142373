import React, { useEffect, useState } from 'react';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faRepeat, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CURRENCY from 'utils/constants/currency';
import './TariffTable.scss';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import Modal from 'components/modal/Modal';
import ModalReferenceEquivalences from './ModalReferenceEquivalences/ModalReferenceEquivalences';
import BtnEquivalence from './BtnEquivalence/BtnEquivalence';
import { IData } from 'utils/hooks/http/search-by-reference.http';
import { formatNumberWithSpaces } from 'utils/utils';


type Props = {
  data;
  loading?: boolean;
  handlePageClick?: (data) => Promise<void>;
  totalPage?: number;
  currentPage?: number;
  total?: number;
  tableRef?: React.RefObject<HTMLTableElement>;
  loadingContent?: boolean;
  header?: boolean;
  onRowClick?: (row) => void;
  setValueItems?;
  removeRow?;
  valueChangeRepaced?: (number) => void;
  changePartInd?: (boolean, number) => void;
  noFooter?: boolean
  redirect?
  noValidateOrderTotal?: boolean
};

const TariffTable: React.FC<Props> = (props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [totalOrderValidate, setTotalOrderValidate] = useState<Number | null>(null);
  const [refDataAutocomplete, setRefDataAutocomplete] = useState<IData[]>([]);
  const [referenceSearch, setReferenceSearch] = useState<IData>();
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    if (localStorage.getItem('proformaFileInProgress')) {
      const proformaFile = JSON.parse(localStorage.getItem('proformaFileInProgress') as string);

      if (proformaFile?.min_order_value && proformaFile.min_order_value) {
        setTotalOrderValidate(proformaFile.min_order_value);

      }
    }
  }, []);


  let total_footer = 0;

  function show(id: string, type) {
    document.getElementById(String(id))!.style.display = type;
  }


  const validate_ind_franco = {};

  props.data.map((item) => {
    item.parts.map((i) => {
      if (i.ind_equivalence_select) {
        if (!Object.keys(validate_ind_franco).some((key) => key === i.ind_equivalence.company)) {
          validate_ind_franco[i.ind_equivalence.company] = { total: i.ind_equivalence.total, franco: i.ind_equivalence.franco };
        } else {
          validate_ind_franco[i.ind_equivalence.company].total += i.ind_equivalence.total;
        }

        total_footer = total_footer + i.ind_equivalence.total;
      } else {
        total_footer = total_footer + i.total;
      }


    });
  });


  return (
    <div className="table-wrapper-tariff-parts">
      {props.loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {props.noValidateOrderTotal && totalOrderValidate && Number(total_footer) < Number(totalOrderValidate)
            ?
            <div className="min_total_order" >

              <FontAwesomeIcon icon={faExclamationCircle} />  {t('_min_total_order') + ' ' + `${CURRENCY[user!.currency]}${Number(totalOrderValidate).toFixed(2)}`}

            </div>
            :
            null
          }
          <table>

            <thead className="headings">


              <tr className="headings-row">
                <th className="heading" colSpan={isMobile ? 1 : 4} style={{ width: '200' }}>
                  {/* <div className="table-title">{t('_submitted_info')}</div> */}
                  <div className="table-title">{' '}</div>
                </th>
                <th className="heading" colSpan={isMobile ? 2 : 4}>
                  {/* <div className="table-title">{t('_information')}</div> */}
                  <div className="table-title">{' '}</div>
                </th>
                <th className="heading" rowSpan={2}>
                  <div className="table-title">{t('_total')}</div>
                </th>
              </tr>
              <tr className="headings-row">

                {!isMobile && (
                  <th className="heading" colSpan={1} >
                    {t('_num')}
                  </th>
                )}
                <th className="heading" colSpan={1}>
                  {t('_reference_sumitted')}
                </th>
                {!isMobile && (
                  <th className="heading" colSpan={1}>
                    {t('_brand')}
                  </th>
                )}
                {!isMobile && (
                  <th className="heading" colSpan={1}>
                    {t('_description')}
                  </th>
                )}
                <th className="heading" colSpan={1}>
                  {t('_sell_price')}
                </th>

                {!isMobile && (
                  <th className="heading" colSpan={1}>
                    {t('_price_submited_table')}
                  </th>
                )}


                <th className="heading" colSpan={1}>
                  {t('_qte')}
                </th>
                {!isMobile && (
                  <th className="heading" colSpan={1}>
                    {t('_statut')}
                  </th>
                )}
              </tr>


            </thead>

            {props.loadingContent ? (
              <tbody>
                <tr>
                  <td colSpan={7}>
                    <Spinner class="extra-small" />
                  </td>
                </tr>
              </tbody>
            ) : props.data.length ? (
              <tbody className="body">
                {props.data.map((item, key) => {
                  let total_provider = 0;
                  let validate_franco = false;
                  let total_provider_franco = 0;

                  if (item.parts.length == 1) {
                    item.parts.map((i) => {
                      if (i.ind_equivalence && i.ind_equivalence_select) {
                        if (
                          Number(i.ind_equivalence.quantity) >= i.ind_equivalence.min_quantity &&
                          Number(i.ind_equivalence.quantity) % i.ind_equivalence.uv == 0
                        ) {
                          total_provider += i.ind_equivalence.total;
                        }
                      } else {
                        if (Number(i.quantity) >= i.min_quantity && Number(i.quantity) % i.uv == 0) {
                          total_provider += i.total;
                        }
                      }
                    });

                    validate_franco =
                      total_provider <
                      (item.parts[0].ind_equivalence && item.parts[0].ind_equivalence_select
                        ? item.parts[0].ind_equivalence.franco!
                        : item.franco!);
                  } else {
                    item.parts.map((i) => {
                      if (i.ind_equivalence && i.ind_equivalence_select) {
                        if (Number(i.quantity) >= i.min_quantity && Number(i.quantity) % i.uv == 0) {
                          total_provider += i.ind_equivalence.total;
                        }
                      } else {
                        if (Number(i.quantity) >= i.min_quantity && Number(i.quantity) % i.uv == 0) {
                          total_provider += i.total;
                          total_provider_franco += i.total;
                        }


                        validate_franco = total_provider_franco < item.franco!;
                      }
                    });
                  }


                  const company: string[] = [];

                  const total = { FRANCO: { total: 0, franco: 0 } };

                  {
                    item.parts.map((i) => {
                      if (!i.ind_equivalence_select) {
                        total['FRANCO'].total += i.total;
                      } else {
                        if (!Object.keys(total).some((key) => key === i.ind_equivalence.company)) {
                          total[i.ind_equivalence.company] = { total: i.ind_equivalence.total, franco: i.ind_equivalence.franco };
                        } else {
                          total[i.ind_equivalence.company].total += i.ind_equivalence.total;
                        }
                      }
                    });
                  }

                  return (
                    <React.Fragment key={item.code_fou + key}>
                      <tr key={item.code_fou + key} className="body-row" onClick={() => null}>
                        <td colSpan={isMobile ? 7 : 9} className="cell-title">
                          <div className="cell-title-franco">
                            {item.parts.length == 1 ? (
                              <span
                                className={`franco ${(
                                  item.parts[0].ind_equivalence_select
                                    ? item.parts[0].ind_equivalence.franco >
                                    validate_ind_franco[item.parts[0].ind_equivalence.company].total
                                    : validate_franco
                                )
                                  ? 'red'
                                  : 'not-red'
                                  }`}
                              >
                                {(
                                  item.parts[0].ind_equivalence_select
                                    ? item.parts[0].ind_equivalence.franco >
                                    validate_ind_franco[item.parts[0].ind_equivalence.company].total
                                    : validate_franco
                                ) ?
                                  (
                                    <React.Fragment key={item.code_fou + item.parts[0].reference_full + key}>
                                      <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        onMouseEnter={() =>
                                          show(String('franco' + String(item.code_fou + item.parts[0].reference_full + key)), 'block')
                                        }
                                        onMouseLeave={() =>
                                          setTimeout(() => {
                                            show(String('franco' + String(item.code_fou + item.parts[0].reference_full + key)), 'none');
                                          }, 100)
                                        }
                                      />

                                      <div
                                        id={String('franco' + String(item.code_fou + item.parts[0].reference_full + key))}
                                        className="min_franco"
                                      >
                                        {t('_min_franco')} {CURRENCY[user!.currency]}
                                        {item.parts[0].ind_equivalence && item.parts[0].ind_equivalence_select
                                          ? item.parts[0].ind_equivalence.franco
                                            ? item.parts[0].ind_equivalence.franco
                                            : '-'
                                          : item.franco
                                            ? item.franco
                                            : '-'}{' '}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    ''
                                  )}
                                {t('_franco')}: {CURRENCY[user!.currency]}
                                {item.parts[0].ind_equivalence && item.parts[0].ind_equivalence_select
                                  ? item.parts[0].ind_equivalence.franco
                                    ? item.parts[0].ind_equivalence.franco
                                    : '-'
                                  : item.franco
                                    ? item.franco
                                    : '-'}
                              </span>
                            ) : (
                              <div className="franco_ind_equivalence">

                                <span className={`franco ${validate_franco ? 'red' : 'not-red'}`}>
                                  {validate_franco ? (
                                    <React.Fragment key={item.code_fou + item.parts[0].reference_full + key}>
                                      <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        onMouseEnter={() =>
                                          show(String('franco' + String(item.code_fou + item.parts[0].reference_full + key)), 'block')
                                        }
                                        onMouseLeave={() =>
                                          setTimeout(() => {
                                            show(String('franco' + String(item.code_fou + item.parts[0].reference_full + key)), 'none');
                                          }, 100)
                                        }
                                      />

                                      <div
                                        id={String('franco' + String(item.code_fou + item.parts[0].reference_full + key))}
                                        className="min_franco"
                                      >
                                        {t('_min_franco')} {CURRENCY[user!.currency]}
                                        {item.franco ? item.franco : '-'}{' '}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    ''
                                  )}

                                  {t('_franco')}: {CURRENCY[user!.currency]}
                                  {item.franco ? item.franco : '-'}
                                </span>

                                {item.parts.map((i, n) => {
                                  if (i.ind_equivalence_select && !company.includes(i.ind_equivalence.company)) {
                                    company.push(i.ind_equivalence.company);

                                    return (
                                      <span
                                        key={i.reference_full + item.name + n}
                                        className={`franco ${i.ind_equivalence.franco > validate_ind_franco[i.ind_equivalence.company].total
                                          ? 'red'
                                          : 'not-red'
                                          }`}
                                      >
                                        {i.ind_equivalence.franco > validate_ind_franco[i.ind_equivalence.company].total ? (
                                          <React.Fragment key={i.reference_full + i.ind_equivalence.company + n}>
                                            <FontAwesomeIcon
                                              icon={faExclamationCircle}
                                              onMouseEnter={() =>
                                                show(String('franco' + String(i.reference_full + i.ind_equivalence.company + n)), 'block')
                                              }
                                              onMouseLeave={() =>
                                                setTimeout(() => {
                                                  show(String('franco' + String(i.reference_full + i.ind_equivalence.company + n)), 'none');
                                                }, 100)
                                              }
                                            />
                                            <div
                                              id={String('franco' + String(i.reference_full + i.ind_equivalence.company + n))}
                                              className="min_franco"
                                            >
                                              {t('_min_franco')} {CURRENCY[user!.currency]}
                                              {i.ind_equivalence.franco ? i.ind_equivalence.franco : '-'}{' '}
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          ''
                                        )}
                                        {t('_franco') + `( ${i.ind_equivalence.company} )`}: {CURRENCY[user!.currency]}
                                        {i.ind_equivalence.franco ? i.ind_equivalence.franco : '-'}
                                      </span>
                                    );
                                  }
                                })}
                              </div>
                            )}

                            <span> {item.name == 'NOT_SUPPLIED' ? <span className="red">{t('_not_supplied')} </span> : item.name}</span>
                            <div className="total_ind_equivalence">
                              {Object.keys(total).length > 1 && item.parts.length != 1
                                ? Object.keys(total).map((v, k) => {
                                  return (
                                    <span key={k}>
                                      {t('_total') + `( ${v} )`}: {CURRENCY[user!.currency]}
                                      {total[v].total ? formatNumberWithSpaces(total[v].total) : '-'}
                                    </span>
                                  );
                                })
                                : null}

                              <span>
                                {t('_total')}: {CURRENCY[user!.currency]}
                                {formatNumberWithSpaces(total_provider)}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>

                      {item.parts.map((i, n) => {
                        const validate_quantity =
                          i.ind_equivalence && i.ind_equivalence_select
                            ? i.ind_equivalence.quantity! < i.ind_equivalence.min_quantity
                            : i.quantity! < i.min_quantity;

                        const validate_uv =
                          i.ind_equivalence && i.ind_equivalence_select
                            ? i.ind_equivalence.quantity! % i.ind_equivalence.uv != 0
                            : i.quantity! % i.uv != 0;


                        const validate_max_quantity = i.max_quantity ? (
                          i.ind_equivalence && i.ind_equivalence_select
                            ? i.ind_equivalence.quantity! > i.ind_equivalence.max_quantity
                            : i.quantity! > i.max_quantity) : false;

                        return (
                          <tr key={i.reference_full + item.name + n} className="body-row">
                            {
                              !isMobile && (
                                <td colSpan={1} className="cell" style={{ width: '30' }}>{n + 1}</td>
                              )
                            }
                            <td colSpan={1} className="cell" style={{ width: '200' }}>
                              <div className="reference-head">
                                <div className="reference-name">
                                  <div className="country_restriction">
                                    {i.country_restriction != null && (
                                      <React.Fragment key={i.reference_full + item.name + n + i.country_restriction.name}>
                                        <FontAwesomeIcon
                                          icon={faFlag}
                                          className="font-icon"
                                          onMouseEnter={() =>
                                            show(String('country_restriction' + String(i.reference_full + item.name + n + i.country_restriction.name)), 'block')
                                          }
                                          onMouseLeave={() =>
                                            setTimeout(() => {
                                              show(String('country_restriction' + String(i.reference_full + item.name + n + i.country_restriction.name)), 'none');
                                            }, 100)
                                          }
                                        />
                                        <div
                                          id={String('country_restriction' + String(i.reference_full + item.name + n + i.country_restriction.name))}
                                          className="restriction"
                                        >
                                          {`${t('_country_restriction')} : ${i.country_restriction.name}`}
                                        </div>
                                      </React.Fragment>

                                    )}
                                  </div>
                                  {validate_quantity && (
                                    <React.Fragment key={i.reference_full + item.name + n}>
                                      <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        className="font-icon"
                                        onMouseEnter={() =>
                                          show(String('ref_validate_quantity' + String(i.reference_full + item.name + n)), 'block')
                                        }
                                        onMouseLeave={() =>
                                          setTimeout(() => {
                                            show(String('ref_validate_quantity' + String(i.reference_full + item.name + n)), 'none');
                                          }, 100)
                                        }
                                      />
                                      <div
                                        id={String('ref_validate_quantity' + String(i.reference_full + item.name + n))}
                                        className="min_quantity"
                                      >
                                        {' '}
                                        {t('_min_quantity')} {i.min_quantity ? i.min_quantity : '-'}{' '}
                                      </div>
                                    </React.Fragment>
                                  )}

                                  {validate_max_quantity && (
                                    <React.Fragment key={i.reference_full + item.name + n}>
                                      <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        className="font-icon"
                                        onMouseEnter={() =>
                                          show(String('ref_validate_max_quantity' + String(i.reference_full + item.name + n)), 'block')
                                        }
                                        onMouseLeave={() =>
                                          setTimeout(() => {
                                            show(String('ref_validate_max_quantity' + String(i.reference_full + item.name + n)), 'none');
                                          }, 100)
                                        }
                                      />
                                      <div
                                        id={String('ref_validate_max_quantity' + String(i.reference_full + item.name + n))}
                                        className="min_quantity"
                                      >
                                        {' '}
                                        {
                                          (i.max_quantity <= 0
                                            ? t('_validate_max_quantity_negative')
                                            : t('_validate_max_quantity') + (i.max_quantity ? i.max_quantity : '-'))
                                        }
                                        {' '}
                                      </div>
                                    </React.Fragment>
                                  )}
                                  {i.replace != null ? (
                                    <React.Fragment>
                                      {i.replace_status ? (
                                        <div className="referance_replaced">
                                          <span
                                            className={i.ind_equivalence_select ? 'reference_old ind_reference_selected' : 'reference_old'}
                                            onClick={() => (props.changePartInd ? props.changePartInd(false, i.index) : null)}
                                          >
                                            {(i.codfou ? i.codfou : '') + i.replace.reference}
                                            <span> {t('_referance_replaced')}</span>
                                          </span>
                                          <span
                                            className={i.ind_equivalence_select ? 'item_replaced ind_reference_selected' : 'item_replaced'}
                                            onClick={() => (props.changePartInd ? props.changePartInd(false, i.index) : null)}
                                          >
                                            {' '}
                                            {(i.codfou ? i.codfou : '') + i.reference}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="referance_replaced_false">
                                          <span
                                            className={i.ind_equivalence_select ? 'reference_old ind_reference_selected' : 'reference_old'}
                                            onClick={() => (props.changePartInd ? props.changePartInd(false, i.index) : null)}
                                          >
                                            {(i.codfou ? i.codfou : '') + i.replace.reference}
                                            <span> {t('_referance_replaced_false')}</span>
                                          </span>
                                          <span
                                            className={i.ind_equivalence_select ? 'item_replaced ind_reference_selected' : 'item_replaced'}
                                            onClick={() => (props.changePartInd ? props.changePartInd(false, i.index) : null)}
                                          >
                                            {' '}
                                            {(i.codfou ? i.codfou : '') + i.reference}
                                          </span>
                                        </div>
                                      )}
                                      {/* <FontAwesomeIcon icon={faRepeat} onClick={() => props.valueChangeRepaced(i.index)} className="iconRepeat" /> */}
                                      {/* <FontAwesomeIcon icon={faRepeat} className="iconRepeat" /> */}
                                    </React.Fragment>
                                  ) : (
                                    <span
                                      className={i.ind_equivalence_select ? 'reference ind_reference_selected' : 'reference'}
                                      onClick={() => (props.changePartInd ? props.changePartInd(false, i.index) : null)}
                                    >
                                      {(i.codfou ? i.codfou : '') + i.reference}
                                    </span>
                                  )}
                                </div>

                                {i.ind_equivalence ? (
                                  <div className="ind_equivalence">
                                    <span
                                      className={
                                        i.ind_equivalence_select ? 'reference_ind_equivalence_red' : 'reference_ind_equivalence_blue'
                                      }
                                      onClick={() => (props.changePartInd ? props.changePartInd(true, i.index) : null)}
                                    >
                                      {i.ind_equivalence.codfou + i.ind_equivalence.reference}
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </td>
                            {
                              !isMobile && (
                                <td colSpan={1} className="cell">
                                  <span className={i.ind_equivalence_select ? 'name_red' : ''}>
                                    {' '}
                                    {i.ind_equivalence && i.ind_equivalence_select ? i.ind_equivalence.company : i.name}{' '}
                                  </span>
                                </td>
                              )
                            }

                            {
                              !isMobile && (
                                <td colSpan={1} className="cell">
                                  <span >
                                    {i.description}
                                  </span>
                                </td>
                              )
                            }
                            <td colSpan={1} className="cell">
                              <div className="price_head">
                                <div className="price">
                                  {i.price ? (
                                    <span className={`${i.replace && i.replace_status ? 'color-red' : ''}`}>
                                      {CURRENCY[user!.currency]}
                                      {formatNumberWithSpaces(i.price)}
                                    </span>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </div>

                                {i.ind_equivalence ? (
                                  <div className="ind_equivalence">
                                    {i.ind_equivalence && Number(i.ind_equivalence.price) ? (
                                      <span
                                        className={i.ind_equivalence_select ? 'price_ind_equivalence_red' : 'price_ind_equivalence_blue'}
                                      >
                                        {CURRENCY[user!.currency]}
                                        {formatNumberWithSpaces(i.ind_equivalence.price)}
                                      </span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </td>

                            {
                              !isMobile && (
                                <td colSpan={1} className="cell">
                                  <div className="price_head">
                                    <div className="price">
                                      {i.price_from_file ? (
                                        <span >
                                          {CURRENCY[user!.currency]}
                                          {formatNumberWithSpaces(i.price_from_file)}
                                        </span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              )
                            }

                            <td colSpan={1} className="cell">
                              <QuantityInput
                                value={i.quantity!}
                                index={i.index!}
                                setValueItems={props.setValueItems}
                                uv={i.uv}
                                red={(validate_uv || validate_quantity || validate_max_quantity) && item.name != 'NOT_SUPPLIED'}
                              />
                              {i.uv && i.uv > 1 && (
                                <React.Fragment>
                                  <span className="color-yellow">UV * {i.uv}</span>
                                  <br></br>
                                </React.Fragment>
                              )}

                              {validate_uv && !validate_quantity && item.name != 'NOT_SUPPLIED' && (
                                <span className="color-red">
                                  {t('_validate_uv')} : {i.uv}
                                </span>
                              )}
                              {!validate_uv && validate_quantity && item.name != 'NOT_SUPPLIED' && (
                                <span className="color-red">
                                  {t('_min_quantity')} {i.min_quantity ? i.min_quantity : '-'}
                                </span>
                              )}
                              {!validate_uv && !validate_quantity && validate_max_quantity && item.name != 'NOT_SUPPLIED' && (
                                <span className="color-red">
                                  {(i.max_quantity <= 0
                                    ? t('_validate_max_quantity_negative')
                                    : t('_validate_max_quantity') + (i.max_quantity ? i.max_quantity : '-'))
                                  }
                                </span>
                              )}
                              {validate_uv && validate_quantity && validate_max_quantity && item.name != 'NOT_SUPPLIED' && (
                                <div>
                                  <span className="color-red">
                                    {t('_validate_uv')} : {i.uv}
                                  </span>
                                  <br></br>
                                  <span className="color-red">
                                    {t('_min_quantity')} {i.min_quantity ? i.min_quantity : '-'}
                                  </span>
                                  <br></br>
                                  <span className="color-red">
                                    {(i.max_quantity <= 0
                                      ? t('_validate_max_quantity_negative')
                                      : t('_validate_max_quantity') + (i.max_quantity ? i.max_quantity : '-'))
                                    }
                                  </span>
                                </div>
                              )}
                            </td>
                            {
                              !isMobile && (
                                <td colSpan={1} className="cell">
                                  {!validate_quantity && !validate_uv ? (
                                    <div className="status-green">{t('_validate')} </div>
                                  ) : (
                                    <div className={i.codfou ? 'status-green' : 'status-red'}>
                                      {i.codfou ? t('_supplied') : t('_non_supplied')}{' '}
                                    </div>
                                  )}
                                </td>
                              )
                            }
                            <td colSpan={1} className="cell">
                              <div className="action">


                                {i.ind_equivalence && i.ind_equivalence_select
                                  ? i.ind_equivalence.total
                                    ? CURRENCY[user!.currency]
                                    : ''
                                  : i.total
                                    ? CURRENCY[user!.currency]
                                    : ''}{' '}
                                {i.ind_equivalence && i.ind_equivalence_select
                                  ? i.ind_equivalence.total
                                    ? formatNumberWithSpaces(i.ind_equivalence.total)
                                    : '-'
                                  : i.total
                                    ? formatNumberWithSpaces(i.total)
                                    : '-'}
                                <div className="action_btn">
                                  <BtnEquivalence item={i} setRefDataAutocomplete={setRefDataAutocomplete} setReferenceSearch={setReferenceSearch} setShowModal={setShowModal} />

                                  {props.removeRow ? (
                                    Object.keys(props.data).length == 1 && item.parts.length == 1

                                      ?
                                      <button className="action_btn_remove" onClick={() => props.redirect()}>
                                        <FontAwesomeIcon icon={faTrashCan} className="action_btn_remove_icon" />
                                      </button>
                                      :
                                      <button className="action_btn_remove" onClick={() => props.removeRow(i.index)}>
                                        <FontAwesomeIcon icon={faTrashCan} className="action_btn_remove_icon" />
                                      </button>


                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={20} className="notFond1">{`${t('_no_data_found')}`}</td>
                </tr>
              </tbody>
            )}
          </table>
          {!props.noFooter && (
            <div className="tfoot">

              <div className="tfoot-td-1">{t('_total_h_t')}</div>
              <div className="tfoot-td-2">
                {CURRENCY[user!.currency]} {props.total ? formatNumberWithSpaces(props.total) : formatNumberWithSpaces(total_footer)}

              </div>

            </div>
          )}
        </React.Fragment>
      )
      }

      <Modal
        openModal={showModal}
        setOpenModal={setShowModal}
        childrenComponent={
          <ModalReferenceEquivalences referenceSearch={referenceSearch} refDataAutocomplete={refDataAutocomplete} setShowModal={setShowModal} />
        }
      />
    </div >
  );
};

TariffTable.displayName = 'TariffTable';

export default TariffTable;
