import React from 'react';

import './AutomotorBuyerHomePage.scss';
import AMERIGO from 'assets/images/AMERIGO_Global_Parts.png';

type Props = {};

const AutomotorBuyerHomePage: React.FC<Props> = () => {


  return <div className="automotor-buyer-home-page">
    <img src={AMERIGO} className="svg-blue" alt="espace-logo" />

  </div >;
};

export default AutomotorBuyerHomePage;


