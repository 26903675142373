import React, { useCallback, useEffect, useState } from 'react';
import './ModalDlrnHernrCodfouByspecifyingReferenceContent.scss';
import IconItem from '../../../../../../components/Item/IconItem/IconItem';
import { faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { setDlrnHernrCodfouByspecifyingReferenceActive } from 'store/slices/dlrnHernrCodfouByspecifyingReference.slice';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { IOption } from 'interfaces/select';
import Select from 'react-select';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type Props = {
  setOpenModal: (e: boolean) => void;
};


type ICsvForm = {
  file: File | null | undefined;
  codfou: IOption;
  equivalent_codfou: IOption[];
};
const ModalDlrnHernrCodfouByspecifyingReferenceContent: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const http = useHttp();
  const [providers, setProviders] = useState<IOption[]>([]);
  const { isMobile, isTablet } = useDeviceDetect();
  const [filteredEquivalentOptions, setFilteredEquivalentOptions] = useState<IOption[]>([]);

  const { control, getValues, watch, handleSubmit, setValue, formState: { errors } } = useForm<ICsvForm>({
    mode: 'onSubmit',
    defaultValues: {
      file: null,
    },
  });


  const onDrop = useCallback((acceptedFile) => {
    if (acceptedFile.length > 0) {
      setValue('file', acceptedFile[0], { shouldValidate: true });
    }
  }, [setValue]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
  });

  function getSupplier() {
    http.allowedList.getProviders().then(res => {
      setProviders(res.data.map((item) => ({ value: item.code, label: `${item.company} (${item.code})`, source: item.source })));
    });
  }

  useEffect(() => {
    getSupplier();
  }, []);

  function search() {
    setIsLoading(true);
    const values = getValues();

    // Construim un obiect FormData
    const formData = new FormData();

    formData.append('codfou', values.codfou.value);

    if (Array.isArray(values.equivalent_codfou) && values.equivalent_codfou.length) {
      values.equivalent_codfou.forEach((item, index) => {
        formData.append(`equivalent_codfou[${index}]`, item.value);
      });
    }

    if (values.file) {
      formData.append('file', values.file);
    }

    http.buyerHttp.postList(formData).then((res) => {

      const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', values.codfou.value + '_CHECK_EQUIVALENCES.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    }).finally(() => {
      setIsLoading(false);
    });
  }


  useEffect(() => {
    const selectedCodfou = watch('codfou');

    if (selectedCodfou) {
      setFilteredEquivalentOptions(() => {
        return selectedCodfou.source === 'sup'
          ? providers.filter((item) => item.source === 'man')
          : providers;
      });
    } else {
      setFilteredEquivalentOptions([]);
    }
  }, [watch('codfou'), providers]);


  return (

    <div className="list-dlrn-hernr-codfou-by-specifying-reference" >
      <div className="modal-dialog">
        <div className="close">
          <IconItem icon={faClose} onClick={() => { props.setOpenModal(false); setDlrnHernrCodfouByspecifyingReferenceActive(false); }} />
        </div>
        <div className="list-title">
          {t('_rading_DLNR_HERNR_CODFOU_by_specifying_reference')}
        </div>
        <form onSubmit={handleSubmit(search)} >
          <div className="list-content">
            <div className={`price-file-upload-container ${errors.file && 'invalid'}`}>
              <i className="fas fa-folder-open price-folder-upload" />
              <div {...getRootProps()} className="price-title-upload ">
                {t('_drag_drop_here')}
                <br />
                <strong className="browse-files">{t('_csv_browse')}</strong>
              </div>
              <Controller
                control={control}
                name="file"
                rules={{ required: t('_file_required') }}
                render={({ field }) => (
                  <input
                    {...getInputProps({
                      onChange: (e) => field.onChange(e.target.files?.[0] || null),
                    })}
                    accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    type="file"
                    style={{ display: 'none' }}
                  />
                )}
              />
            </div>
            {
              !errors.file && watch('file') &&
              <div className="uploaded-file">
                <FontAwesomeIcon className="file-icon" icon={faFileExcel} />
                <div className="file-data">
                  <div className="file-name">{watch('file')?.name || ''}</div>
                  <FontAwesomeIcon className="remove-file" icon={faClose} onClick={() => setValue('file', null, { shouldValidate: true })} />
                </div>
              </div>
            }
            <div className="codfou-reference">
              <div className="list-row">
                <div className="list-row-label">{t('_provider')}<span>*</span></div>
                <div className="provider-dropdown-item">
                  <Controller
                    control={control}
                    name="codfou"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <Select
                        className={`select-dropdown ${errors.codfou && 'required'}`}
                        onChange={(val) => {
                          onChange(val);

                        }}
                        options={providers}
                        isSearchable={!isMobile && !isTablet}
                        placeholder={t('_provider')}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 50 })
                        }}
                      />
                    )}
                  />
                </div>

              </div>

            </div>

            <div className="separator"></div>

            <div className="equivalent-codfou">
              <div className="list-row">
                <div className="list-row-label">{t('_provider') + ' ' + t('_equivalent')}<span>*</span></div>
                <div className="provider-dropdown-item">
                  <Controller
                    control={control}
                    name="equivalent_codfou"
                    rules={{ required: false }}
                    render={({ field: { onChange } }) => (
                      <Select
                        key={filteredEquivalentOptions.length}
                        className={`select-dropdown ${errors.equivalent_codfou && 'required'}`}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        isMulti
                        options={filteredEquivalentOptions}
                        isSearchable={!isMobile && !isTablet}
                        placeholder={t('_provider')}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 50 })
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="action">
            <DefaultButton isloading={isLoading} text={t('_search')} blue />
          </div>
        </form >

      </div>


    </div >
  );
};

export default ModalDlrnHernrCodfouByspecifyingReferenceContent;