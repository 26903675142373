import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { notify } from 'utils/marketplace';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import { IFilterValue } from 'interfaces/tyre';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import i18n from 'i18n';
import Select from 'react-select';
import TextInput from 'components/inputs/TextInput/TextInput';
import 'react-datepicker/dist/react-datepicker.css';
import './CreatePartsReplacement.scss';


type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  providers;
  loading: boolean;
  editData?
};

type IForm = {
  codfou_old: IFilterValue;
  codfou_new: IFilterValue;
  ref_old: string;
  ref_new: string;
  price: number;
}

const CreatePartsReplacement: React.FC<Props> = (props) => {
  const http = useHttp();
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [loadingSave, setLoadingSave] = useState<boolean>(false);

  const validate = () => {
    return Yup.object().shape({
      codfou_old: !props.editData ? Yup.object().shape({
        label: Yup.string().required(t('_fild_required')),
        value: Yup.string().required(t('_fild_required')),
      }) : Yup.object().shape({
        label: Yup.string().notRequired(),
        value: Yup.string().notRequired(),
      }),
      codfou_new: Yup.object().shape({
        label: Yup.string().required(t('_fild_required')),
        value: Yup.string().required(t('_fild_required')),
      }),
      ref_old: Yup.string().required(t('_fild_required')),
      ref_new: Yup.string().required(t('_fild_required')),
      price: Yup.string().required(t('_fild_required')),
    });
  };

  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<IForm>({ resolver: yupResolver(validate()) });

  useLocaleDatePicker(i18n.language);

  const onSubmit = async () => {
    const values = getValues();

    setLoadingSave(true);
   
    const params = {
      codfou_old: String(values.codfou_old.value),
      codfou_new: values.codfou_new ? String(values.codfou_new.value) : null,
      ref_old: values.ref_old,
      ref_new: values.ref_new,
      price: String(values.price),
    };

    if(props.editData){
      http.partsReplacement.editPartsReplacement(params, props.editData.id)
        .then(() => {
          notify(t('_created_successfully'), 'success', t('_success'));
          props.setOpenModal(false);
          props.setSubmit(true);
          setLoadingSave(false);
        })
        .catch((err) => {
          notify(err?.error);
          setLoadingSave(false);
        });
    } else {
      http.partsReplacement.createPartsReplacement(params)
        .then(() => {
          notify(t('_created_successfully'), 'success', t('_success'));
          props.setOpenModal(false);
          props.setSubmit(true);
          setLoadingSave(false);
        })
        .catch((err) => {
          notify(err?.error);
          setLoadingSave(false);
        });
    }
  };

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });

  useEffect(() => {
    if(props.editData){
      props.providers.forEach((item) => {
        if(item.value == props.editData.codfou_old){
          setValue('codfou_old', item);
        }

        if(item.value == props.editData.codfou_new){
          setValue('codfou_new', item);
        }
      });

      setValue('ref_old', props.editData.ref_old);
      setValue('ref_new', props.editData.ref_new);
      setValue('price', props.editData.price);
    }
  }, [props.editData, props.providers]);

  return (
    <div className="create-parts-replacement-container">
      <div
        className="close-modal"
        onClick={() => {
          props.setOpenModal(false);
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
      <div className="title">{props.editData ? t('_edit_part_replacement') : t('_create_part_replacement')}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-info">
          <div className="row">

            <div className="container">
              <label>{t('_codfou_old')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="codfou_old"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className={`select-dropdown ${props.editData ? 'disabled' : ''}`}
                        onChange={(val) => {
                          onChange(val);

                          if(val){
                            setValue('codfou_new', props.providers.find(provider => val?.value === provider.value));
                          }
                        }}
                        options={props.providers}
                        placeholder={t('_codfou_old')}
                        styles={customStyles(errors.codfou_old?.label?.type)}
                        isSearchable={!isMobile}
                        isDisabled={!!props.editData}
                      />
                    );
                  }}
                />
                <p className="error">{errors.codfou_old?.label?.type == 'required' ? errors.codfou_old?.label?.message : ''}</p>
              </div>
            </div>

            <div className="container">
              <label>{t('_codfou_new')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="codfou_new"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={props.providers}
                        placeholder={t('_codfou_new')}
                        styles={customStyles(errors.codfou_new?.label?.type)}
                        isSearchable={!isMobile}
                      />
                    );
                  }}
                />
                <p className="error">{errors.codfou_new?.label?.type == 'required' ? errors.codfou_new?.label?.message : ''}</p>
              </div>
            </div>

          </div>
          <div className="row">
            <div className="container">
              <label>{t('_ref_old')}</label>
              <div className="input-item">
                <TextInput placeholder={t('_ref_old')} {...register('ref_old')} disabled={!!props.editData} />
                <p className="error">{errors.ref_old?.type == 'required' ? errors.ref_old?.message : ''}</p>
              </div>
            </div>
            <div className="container">
              <label>{t('_ref_new')}</label>
              <div className="input-item">
                <TextInput placeholder={t('_ref_new')} {...register('ref_new')} />
                <p className="error">{errors.ref_new?.type == 'required' ? errors.ref_new?.message : ''}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <label>{t('_price')}</label>
              <div className="input-item">
                <TextInput placeholder={t('_price')} {...register('price')} type="number" step="0.01"/>
                <p className="error">{errors.price?.type == 'required' ? errors.price?.message : ''}</p>

              </div>
            </div>
          </div>
        </div>
        <div className="btn-save">
          <button type="submit">
            {loadingSave ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_save')}</div>
          </button>
        </div>
      </form >
    </div >
  );
};

export default CreatePartsReplacement;
